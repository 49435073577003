.html-widget-wrapper {
  ul, ol {
    margin-left: 15px;
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }
}
